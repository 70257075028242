import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/lib/fonts.js\",\"import\":\"Montserrat\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-montserrat\"}],\"variableName\":\"montserrat\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@fortawesome/fontawesome-svg-core/styles.css");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/components/cookie-consent.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/components/header/components/desktop-menu.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/components/header/components/mobile-menu.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/components/header/components/search-box.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/components/header/components/signin-button.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/components/header/components/signup-button.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/components/preview-banner/button.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/components/providers.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/globals.css")